//引入react核心库
import React from "react";
//引入ReactDOM
import ReactDOM from "react-dom";
//
import { BrowserRouter } from "react-router-dom";
//引入App
import App from "./App";
import "./index.css"; // 导入Tailwind CSS

ReactDOM.render(
  <div className="text-black bg-white dark:text-white dark:bg-[#111010]">
    <div className="antialiased max-w-2xl mb-40 flex flex-col md:flex-row mx-4 mt-8 lg:mx-auto">
      <div className="flex-auto min-w-0 mt-6 flex flex-col px-2 md:px-0">
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </div>
    </div>
  </div>,

  document.getElementById("root")
);
