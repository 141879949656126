import { Suspense } from "react";
import "./index.css"; // 导入Tailwind CSS
// import { Img } from "react-image";
import nin1 from "./static/images-school/home/nin1.jpg";
import avatar from "./static/images-school/avatar.jpg";
import vercel from "./static/images-school/vercel.jpg";
import { Image } from "antd";
import logo_td from "./static/images-school/logo/logo_td.png";

import ryu1 from "./static/images-ryudai/ryu1.jpg";
import ryu2 from "./static/images-ryudai/ryu2.jpg";
import ryu3 from "./static/images-ryudai/ryu3.jpg";
import ryu4 from "./static/images-ryudai/ryu4.jpg";
import ryu5 from "./static/images-ryudai/ryu5.jpg";
import ryu6 from "./static/images-ryudai/ryu6.jpg";

import { FaLine } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FaSquareInstagram } from "react-icons/fa6";
import { FaSquareXTwitter } from "react-icons/fa6";
import { FaSquareFacebook } from "react-icons/fa6";

// function Badge(props) {
//   return (
//     <a
//       {...props}
//       target="_blank"
//       className="inline-flex items-center rounded border border-neutral-200 bg-neutral-50 p-1 text-sm leading-4 text-neutral-900 no-underline dark:border-neutral-700 dark:bg-neutral-800 dark:text-neutral-100"
//     />
//   );
// }

// function BlogLink({ slug, name }) {
//   return (
//     <div className="group">
//       <a
//         href={`/blog/${slug}`}
//         className="flex w-full items-center justify-between rounded border border-neutral-200 bg-neutral-50 px-3 py-4 dark:border-neutral-700 dark:bg-neutral-800"
//       >
//         <div className="flex flex-col">
//           <p className="font-medium text-neutral-900 dark:text-neutral-100">
//             {name}
//           </p>
//         </div>
//         <div className="transform text-neutral-700 transition-transform duration-300 group-hover:-rotate-12 dark:text-neutral-300">
//           <ArrowIcon />
//         </div>
//       </a>
//     </div>
//   );
// }

// function ChannelLink({ img, link, name }) {
//   return (
//     <div className="group flex w-full">
//       <a
//         href={link}
//         target="_blank"
//         className="flex w-full items-center justify-between rounded border border-neutral-200 bg-neutral-50 px-3 py-4 dark:border-neutral-700 dark:bg-neutral-800"
//       >
//         <div className="flex items-center space-x-3">
//           <div className="relative h-16">
//             <Image
//               alt={name}
//               src={img}
//               height={64}
//               width={64}
//               sizes="33vw"
//               className="h-16 w-16 rounded-full border border-neutral-200 dark:border-neutral-700"
//               priority
//             />
//             {/* <div className="relative -right-10 -top-6 inline-flex h-6 w-6 items-center rounded-full border border-neutral-200 bg-white p-1 dark:border-neutral-700">
//               <svg width="15" height="11" role="img" aria-label="YouTube logo">
//                 <use href="/sprite.svg#youtube" />
//               </svg>
//             </div> */}
//           </div>
//           <div className="flex flex-col">
//             <p className="font-medium text-neutral-900 dark:text-neutral-100">
//               {name}
//             </p>
//           </div>
//         </div>
//         <div className="transform text-neutral-700 transition-transform duration-300 group-hover:-rotate-12 dark:text-neutral-300">
//           <ArrowIcon />
//         </div>
//       </a>
//     </div>
//   );
// }

function ArrowIcon() {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.07102 11.3494L0.963068 10.2415L9.2017 1.98864H2.83807L2.85227 0.454545H11.8438V9.46023H10.2955L10.3097 3.09659L2.07102 11.3494Z"
        fill="currentColor"
      />
    </svg>
  );
}

function ChannelLink9({ iconsWithLinks }) {
  return (
    <div className="flex justify-center items-center p-4 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 bg-[rgba(255, 255, 255, 0.9)] dark:bg-[rgba(17, 17, 17, 0.9)]">
      <div className="flex space-x-4">
        {iconsWithLinks.map((iconWithLink, index) => (
          <a
            key={index}
            href={iconWithLink.link}
            target="_blank"
            rel="noopener noreferrer"
            className="inline-block"
          >
            {iconWithLink.icon}
          </a>
        ))}
      </div>
    </div>
  );
}

// const Image = ({
//   src,
//   alt,
//   width,
//   height,
//   layout = "intrinsic", // Default layout
//   objectFit = "cover",
//   ...props
// }) => {
//   const styles = {
//     width: layout === "fill" ? "100%" : width,
//     height: layout === "fill" ? "100%" : height,
//     objectFit: objectFit,
//   };

//   return (
//     <div style={{ position: "relative", width, height }}>
//       <img src={src} alt={alt} loading="lazy" style={styles} {...props} />
//     </div>
//   );
// };

export default function Page() {
  var height = document.documentElement.clientHeight;
  var width = document.documentElement.clientWidth;
  var ifMobile = height < width;

  return (
    <div>
      <div
        style={{
          width: "100%",
          height: 50,
          position: "relative",
          // backgroundColor: 'rgba(200, 200, 200, 0.8)', // 背景を少し濃いグレーに設定
          // backgroundColor:transparent
          borderRadius: "8px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            color: "#ffffff", // 文字色を黒に設定
            // paddingLeft: '10px',
            // paddingRight: '10px',
            fontSize: "1.5rem",
          }}
        >
          <h1
            // className="mb-8 font-medium tracking-tighter"
            // style={{ letterSpacing: '0.5px' }}
            className="mb-8 text-3xl font-medium tracking-tighter"
            style={{ letterSpacing: 0 }}
          >
            Ryudai Mamiya
          </h1>
        </div>

        <div
          style={{
            position: "absolute",
            right: 0,
            top: 0,
            width: 80,
            height: 80,
            // backgroundColor: '#ffffff',
            borderRadius: 15,
          }}
        >
          <Image
            alt="Me speaking on stage at React Summit about the future of Next.js"
            src={ryu4}
            style={{ width: 80, height: 80 }}
            priority
            className="rounded-lg object-cover"
          />
        </div>
      </div>

      <p
        className="prose prose-neutral dark:prose-invert"
        style={{
          color: "#ffffff", // 文字色を黒に設定
          fontSize: "1.2rem", // 少し大きめのフォントサイズで視認性を向上
          lineHeight: "1.6", // 行間を広げて読みやすく
          fontWeight: "500", // 適度な太さで視覚的なバランスを調整
          letterSpacing: "0.5px", // 文字間を少し広げて洗練された印象に
          margin: "10px 0", // 上下に余白を追加して整える
        }}
      >
        <br />

        {`🎓 University of Tokyo`}
        <br />
        {`🖊️ Master of 学際情報学`}
        <br />
        {`💡 NTT docomo`}
        <br />
        {`🏃‍♀️ Advertising Marketing`}
        <br />
        {`🔥 生成系AIに関する会社の立ち上げ中`}
      </p>

      <div className="my-8 grid grid-cols-1 gap-4">
        <ChannelLink9
          iconsWithLinks={[
            {
              icon: <FaSquareInstagram color="#4a4a4a" size={64} />,
              link: "https://www.instagram.com/mamiosushi_?igsh=cTBpbDNta3N2OXF5&utm_source=qr",
            },
            {
              icon: <FaSquareFacebook color="#4a4a4a" size={64} />,
              link: "https://www.facebook.com/ryudai.mamiya",
            },
            {
              icon: <FaSquareXTwitter color="#4a4a4a" size={64} />,
              link: "https://x.com/rmcaffs?s=21&t=dBFDg4aqj7cwjpLjoYJvAQ",
            },
            {
              icon: <FaLinkedin color="#4a4a4a" size={64} />,
              link: "https://www.linkedin.com/in/ryudai-mamiya-66318429a?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
            },
          ]}
        />
      </div>

      {/* <p className="prose prose-neutral dark:prose-invert">
        专为有志考取名牌大学研究生院的学生提供最高水平的指导和支持。我们全力帮助每一位学生实现他们的目标，成功迈入理想的学术殿堂。
      </p> */}
      <div className="grid grid-cols-2 grid-rows-3 sm:grid-rows-2 sm:grid-cols-3 gap-4 my-8">
        <Image
          alt="Me speaking on stage at React Summit about the future of Next.js"
          src={ryu1}
          fill
          sizes="(max-width: 768px) 213px, 33vw"
          priority
          className="rounded-lg object-cover"
        />
        <Image
          alt="Me speaking on stage at React Summit about the future of Next.js"
          src={ryu2}
          fill
          sizes="(max-width: 768px) 213px, 33vw"
          priority
          className="rounded-lg object-cover"
        />
        <Image
          alt="Me speaking on stage at React Summit about the future of Next.js"
          src={ryu3}
          fill
          sizes="(max-width: 768px) 213px, 33vw"
          priority
          className="rounded-lg object-cover"
        />
        <Image
          alt="Me speaking on stage at React Summit about the future of Next.js"
          src={ryu4}
          fill
          sizes="(max-width: 768px) 213px, 33vw"
          priority
          className="rounded-lg object-cover"
        />
        <Image
          alt="Me speaking on stage at React Summit about the future of Next.js"
          src={ryu5}
          fill
          sizes="(max-width: 768px) 213px, 33vw"
          priority
          className="rounded-lg object-cover"
        />
        <Image
          alt="Me speaking on stage at React Summit about the future of Next.js"
          src={ryu6}
          fill
          sizes="(max-width: 768px) 213px, 33vw"
          priority
          className="rounded-lg object-cover"
        />
      </div>

      {/* <div className="prose prose-neutral dark:prose-invert">
        <p>
          根据每位学生的理解程度和目标制定量身定制的学习计划。通过个别辅导，彻底攻克学习中的难点和薄弱环节。
          按照你的节奏进行教学，让学习既高效又轻松。 全面的合格对策
          提供详细的志愿学校历年考题分析和面试对策，帮助你制定具体且有策略的学习计划。
          实时更新最新的考试信息和研究生院动态，提供准确的建议和指导。
        </p>
      </div>
      <div style={{ height: 50 }} />
      <h1 className="mb-8 text-xl font-medium tracking-tighter">
        Nin&Ryudai の大学院進学塾
      </h1>
      <div className="my-8 flex w-full flex-col space-x-0 space-y-4 sm:flex-row sm:space-x-4 sm:space-y-0">
        <ChannelLink
          img={avatar}
          name="@leerob"
          link="https://www.youtube.com/@leerob"
        />
        <ChannelLink
          img={vercel}
          name="@vercel"
          link="https://www.youtube.com/@vercelhq"
        />
      </div>
      <div className="prose prose-neutral dark:prose-invert">
        <p>
          Over the past decade, I've written content on my blog and newsletter.
          I try to keep things simple. You'll find writing about technologies
          I'm interested in at the time, or how I'm learning and growing in my
          career, sharing knowledge along the way.
        </p>
      </div>
      <div className="my-8 flex w-full flex-col space-y-4">
        <BlogLink
          name="日本大学院指導 （情報系,デザイン系)"
          slug="developer-experience"
        />
        <BlogLink name="面接面談指導 / 日本語指導" slug="devrel" />
        <BlogLink name="作品集指導 / 小論文指導など" slug="heroku" />
      </div>

     
      <div
        style={{ height: 300, backgroundColor: "#FFF500", borderRadius: 15 }}
      ></div>
      <div style={{ position: "fixed", right: 20, bottom: 20 }}>
        <div
          style={{
            width: 50,
            height: 50,
            backgroundColor: "#ff0000",
            borderRadius: 25,
          }}
        ></div>
      </div> */}
    </div>
  );
}
